@import "mixin";
@import "utilites";
@import "typography";
// ------------ <Global Styles> ------------
html {
	font-size: var(--font-size-root);
	margin: 0;
	
	font-variant-numeric: lining-nums;
	font-feature-settings: 'lnum';
	-moz-font-feature-settings: 'lnum=1';
	-ms-font-feature-settings: 'lnum';
	-webkit-font-feature-settings: 'lnum';
	-o-font-feature-settings: 'lnum';
}
body {
	font-family: var(--font-family-base);
	font-size: var(--font-size-root);
	line-height: var(--font-line-height);
	color: var(--bs-body-color);
	margin: 0;
	
	font-variant-numeric: lining-nums;
	font-feature-settings: 'lnum';
	-moz-font-feature-settings: 'lnum=1';
	-ms-font-feature-settings: 'lnum';
	-webkit-font-feature-settings: 'lnum';
	-o-font-feature-settings: 'lnum';
}
.image-avatar{
	@include flex-square(42px);
	background: var(--cl-color-dark-7);
	border: 1px solid var(--cl-color-dark-5);
	border-radius: 50%;
	overflow: hidden;
}
.wrapper {
	overflow: hidden;
}

.container {
	position: relative;
	max-width: 1260px;

	@media(max-width: $screen-size-mobile) {
		max-width: 390px;
	}
}

a {
	color: inherit;
	text-decoration: underline;

	&:hover,
	&:active,
	&:focus {
		color: inherit;
		text-decoration: none;
	}
}

a,
input,
button {
	transition: all 0.3s ease-in-out;

	&:active,
	&:focus {
		outline: none !important;
	}
}

img {
	max-width: 100%;
}

.heading, .h1, h1, .h2, h2, .h3, h3, .h4, h4, .h5, h5, .h6, h6 {
	font-family: var(--font-family-base);
	margin: 0;
}
.col-vert-hr {
	height: 100%;
	width: 1px;
	background: var(--cl-color-dark-5);

	&.c-light {
		background: var(--cl-color-dark-6);
	}
}
// ------------ </Global Styles> ------------ 

// ------------ <Buttons> ------------ 
.cl-btn {
	@include flex-button(50px);
	text-decoration: none;
	color: #fff;
	border-radius: 8px;
	padding: 10px 30px;
	border: none;
	background: var(--cl-color-primary);
	@include txt-btn('lg');

	&:focus {
		color: #fff;
		background: var(--cl-color-primary);
	}

	&.c-danger {
		background: var(--cl-color-status-danger-shade);
		color: var(--cl-color-status-danger);
		&:hover,
		&:focus,
		&:active {
			color: #fff;
			background: var(--cl-color-status-danger);
		}
	}

	&:hover,
	&:active {
		color: #fff;
		background: var(--cl-color-primary-dark);
	}
	&:disabled{
		background-color: #9e9e9e;
	}
	&.has-arrow {
		&:after {
			content: '';
			display: inline-block;
			width: 12px;
			height: 16px;
			background-image: url("data:image/svg+xml,%3Csvg width='9' height='16' viewBox='0 0 9 16' fill=%22none%22 xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.425 14.6L6.85833 9.16669C7.5 8.52502 7.5 7.47503 6.85833 6.83336L1.425 1.40002' stroke='%23FFF' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap=%22round%22 stroke-linejoin=%22round%22/%3E%3C/svg%3E");
			background-repeat: no-repeat no-repeat;
			background-position: center center;
			background-size: auto 16px;
			margin-left: 14px;
		}
	}
	&.has-plus {
		&:before {
			content: '';
			display: inline-block;
			width: 17px;
			height: 17px;
			background-image: url("data:image/svg+xml,%3Csvg width='17' height='18' viewBox='0 0 17 18' fill=%22none%22 xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.25 9H12.75' stroke='%23FFF' stroke-width='1.5' stroke-linecap=%22round%22 stroke-linejoin=%22round%22/%3E%3Cpath d='M8.5 13.25V4.75' stroke='%23FFF' stroke-width='1.5' stroke-linecap=%22round%22 stroke-linejoin=%22round%22/%3E%3C/svg%3E");
			background-repeat: no-repeat no-repeat;
			background-position: center center;
			background-size: auto 17px;
			margin-right: 14px;
		}
	}
	&.has-pen {
		&:before {
			content: '';
			display: inline-block;
			width: 17px;
			height: 17px;
			background-image: url('../images/icons/edit-2_2.svg');
			background-repeat: no-repeat no-repeat;
			background-position: center center;
			background-size: auto 17px;
			margin-right: 14px;
		}
	}
	&.has-expr {
		&:before {
			content: '';
			display: inline-block;
			width: 17px;
			height: 17px;
			background-image: url('../images/icons/btn-export.svg');
			background-repeat: no-repeat no-repeat;
			background-position: center center;
			background-size: auto 17px;
			margin-right: 14px;
		}
	}
	&.has-fly {
		&:before {
			content: '';
			display: inline-block;
			width: 17px;
			height: 17px;
			background-image: url('../images/icons/send-2.svg');
			background-repeat: no-repeat no-repeat;
			background-position: center center;
			background-size: auto 17px;
			margin-right: 14px;
		}
	}

	&.c-shaded {
		color: var(--cl-color-primary);
		background: var(--cl-color-primary-shade);

		&.has-arrow {
			&:after {
				background-image: url("data:image/svg+xml,%3Csvg width='9' height='16' viewBox='0 0 9 16' fill=%22none%22 xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.425 14.6L6.85833 9.16669C7.5 8.52502 7.5 7.47503 6.85833 6.83336L1.425 1.40002' stroke='%2300AEEF' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap=%22round%22 stroke-linejoin=%22round%22/%3E%3C/svg%3E");
			}
		}
		&.has-plus {
			&:before {
				background-image: url("data:image/svg+xml,%3Csvg width='17' height='18' viewBox='0 0 17 18' fill=%22none%22 xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.25 9H12.75' stroke='%2300AEEF' stroke-width='1.5' stroke-linecap=%22round%22 stroke-linejoin=%22round%22/%3E%3Cpath d='M8.5 13.25V4.75' stroke='%2300AEEF' stroke-width='1.5' stroke-linecap=%22round%22 stroke-linejoin=%22round%22/%3E%3C/svg%3E");
			}
		}
	
		&:hover {
			color: var(--cl-color-primary-dark);
			background: var(--cl-color-primary-shade);
		}
	}
	&.c-transp {
		color: var(--cl-color-primary);
		border: 1px solid var(--cl-color-primary-shade);
		background: transparent;

		&.has-arrow {
			&:after {
				background-image: url("data:image/svg+xml,%3Csvg width='9' height='16' viewBox='0 0 9 16' fill=%22none%22 xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.425 14.6L6.85833 9.16669C7.5 8.52502 7.5 7.47503 6.85833 6.83336L1.425 1.40002' stroke='%2300AEEF' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap=%22round%22 stroke-linejoin=%22round%22/%3E%3C/svg%3E");
			}
		}
		&.has-plus {
			&:before {
				background-image: url("data:image/svg+xml,%3Csvg width='17' height='18' viewBox='0 0 17 18' fill=%22none%22 xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.25 9H12.75' stroke='%2300AEEF' stroke-width='1.5' stroke-linecap=%22round%22 stroke-linejoin=%22round%22/%3E%3Cpath d='M8.5 13.25V4.75' stroke='%2300AEEF' stroke-width='1.5' stroke-linecap=%22round%22 stroke-linejoin=%22round%22/%3E%3C/svg%3E");
			}
		}
		&.has-pen {
			&:before {
				background-image: url('../images/icons/edit-2_2.svg');
			}
		}
		&.has-expr {
			&:before {
				background-image: url('../images/icons/btn-export.svg');
			}
		}
		&.has-fly {
			&:before {
				background-image: url('../images/icons/send-2.svg');
			}
		}
	
		&:hover {
			color: var(--cl-color-primary-dark);
			background: var(--cl-color-primary-shade);
		}

		&.c-dark-3 {
			border-color: var(--cl-color-dark-5);
			color: var(--cl-color-dark-3);

			&.has-plus {
				&:before {
					background-image: url("data:image/svg+xml,%3Csvg width='17' height='18' viewBox='0 0 17 18' fill=%22none%22 xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.25 9H12.75' stroke='%235C6682' stroke-width='1.5' stroke-linecap=%22round%22 stroke-linejoin=%22round%22/%3E%3Cpath d='M8.5 13.25V4.75' stroke='%235C6682' stroke-width='1.5' stroke-linecap=%22round%22 stroke-linejoin=%22round%22/%3E%3C/svg%3E");
				}
			}

			&:hover {
				border-color: var(--cl-color-dark-3);
				color: var(--cl-color-dark-3);
				background: transparent;
			}
		}
	}

	&.icn-r {
		img, svg {
			margin-left: 8px;
		}
	}

	&.s-md {
		@include flex-button(42px);
		padding: 10px 25px;
		@include txt-btn('md');

		&.has-arrow {
			&:after {
				width: 10px;
				height: 13px;
				background-size: auto 13px;
				margin-left: 12px;
			}
		}
		&.has-plus {
			&:before {
				width: 13px;
				height: 13px;
				background-size: auto 13px;
				margin-right: 12px;
			}
		}
		&.has-pen,
		&.has-expr,
		&.has-fly {
			&:before {
				width: 13px;
				height: 13px;
				background-size: auto 13px;
				margin-right: 12px;
			}
		}
	}
	&.s-sm {
		@include flex-button(32px);
		padding: 5px 20px;
		@include txt-btn('sm');

		&.has-arrow {
			&:after {
				width: 8px;
				height: 10px;
				background-size: auto 12px;
				margin-left: 10px;
			}
		}
		&.has-plus {
			&:before {
				width: 13px;
				height: 13px;
				background-size: auto 13px;
				margin-right: 10px;
			}
		}
		&.has-pen,
		&.has-expr {
			&:before {
				width: 13px;
				height: 13px;
				background-size: auto 13px;
				margin-right: 10px;
			}
		}	
	}
}
.btn-icn {
	text-decoration: none;
	color: var(--cl-color-dark-4);
	border-radius: 0;
	padding: 0;
	border: none;
	background: transparent;

	&.size-24 {
		@include flex-button(24px);
		width: 24px;

		&:hover {
			color: var(--cl-color-dark-2);
		}
	}
	&.size-30 {
		@include flex-button(30px);
		width: 30px;

		&:hover {
			color: var(--cl-color-dark-2);
		}
	}
	&.size-42 {
		@include flex-button(42px);
		width: 42px;

		&:hover {
			color: var(--cl-color-dark-2);
		}
	}
	&.bord-circle {
		border-radius: 50%;
		border: 1px solid var(--cl-color-dark-5);

		&:hover {
			border-color: var(--cl-color-dark-2);
		}		
	}
}
.cl-link {
	border: none;
	background: transparent;
	text-decoration: none;
	display: inline-flex;
	align-items: center;
	color: var(--cl-color-primary);
	padding: 0;
	@include txt-btn('lg');

	&:hover {
		color: var(--cl-color-primary);
		text-decoration: underline;
	}
	&.has-arrow {
		&:after {
			content: '';
			display: inline-block;
			width: 12px;
			height: 16px;
			background-image: url("data:image/svg+xml,%3Csvg width='9' height='16' viewBox='0 0 9 16' fill=%22none%22 xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.425 14.6L6.85833 9.16669C7.5 8.52502 7.5 7.47503 6.85833 6.83336L1.425 1.40002' stroke='%2300AEEF' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap=%22round%22 stroke-linejoin=%22round%22/%3E%3C/svg%3E");
			background-repeat: no-repeat no-repeat;
			background-position: center center;
			background-size: auto 16px;
			margin-left: 14px;
			margin-top: 2px;
		}
	}
	&.has-plus {
		&:before {
			content: '';
			display: inline-block;
			width: 17px;
			height: 17px;
			background-image: url("data:image/svg+xml,%3Csvg width='17' height='18' viewBox='0 0 17 18' fill=%22none%22 xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.25 9H12.75' stroke='%2300AEEF' stroke-width='1.5' stroke-linecap=%22round%22 stroke-linejoin=%22round%22/%3E%3Cpath d='M8.5 13.25V4.75' stroke='%2300AEEF' stroke-width='1.5' stroke-linecap=%22round%22 stroke-linejoin=%22round%22/%3E%3C/svg%3E");
			background-repeat: no-repeat no-repeat;
			background-position: center center;
			background-size: auto 17px;
			margin-right: 14px;
		}
	}
	&.has-pen {
		&:before {
			content: '';
			display: inline-block;
			width: 17px;
			height: 17px;
			background-image: url('../images/icons/edit-2_2.svg');
			background-repeat: no-repeat no-repeat;
			background-position: center center;
			background-size: auto 17px;
			margin-right: 14px;
		}
	}

	&.s-md {
		@include txt-btn('md');

		&.has-arrow {
			&:after {
				width: 10px;
				height: 13px;
				background-size: auto 13px;
				margin-left: 12px;
			}
		}
		&.has-plus {
			&:before {
				width: 10px;
				height: 13px;
				background-size: auto 13px;
				margin-right: 12px;
			}
		}
		&.has-pen {
			&:before {
				width: 10px;
				height: 13px;
				background-size: auto 13px;
				margin-right: 12px;
			}
		}
	}
	&.s-sm {
		@include txt-btn('sm');

		&.has-arrow {
			&:after {
				width: 8px;
				height: 10px;
				background-size: auto 12px;
				margin-left: 10px;
			}
		}
		&.has-plus {
			&:before {
				width: 8px;
				height: 10px;
				background-size: auto 12px;
				margin-right: 10px;
			}
		}
		&.has-pen {
			&:before {
				width: 8px;
				height: 10px;
				background-size: auto 12px;
				margin-right: 10px;
			}
		}
	}
}
// ------------ </Buttons> ------------ 

// ------------ <Panels> ------------
.panel {
	background: var(--cl-color-dark-7);
	border-left: 4px solid var(--cl-color-dark-5);
	border-radius: 2px 8px 8px 2px;
	padding: 16px 20px;

	&.s-md {
		padding: 10px 20px;
	}

	&.c-info {
		@include cl-panel('info');
	}
	&.c-success {
		@include cl-panel('success');
	}
	&.c-warning {
		@include cl-panel('warning');
	}
	&.c-danger {
		@include cl-panel('danger');
	}
}
// ------------ <Panels> ------------

// ------------ <Statuses> ------------
.cl-status {
	display: inline-flex;
	align-items: center;
	justify-content: center;

	&.c-info {
		@include cl-status('info');
	}
	&.c-success {
		@include cl-status('success');
	}
	&.c-warning {
		@include cl-status('warning');
	}
	&.c-danger {
		@include cl-status('danger');
	}

	&.st-1 {
		padding: var(--cl-status-type1-padding);
		height: var(--cl-status-type1-height);
		border-radius: var(--cl-status-type1-border-radius);
		font-size: var(--cl-status-type1-font-size);
		font-weight: var(--cl-status-type1-font-weight);
		line-height: 1;

		&::before {
			content: '';
			display: inline-flex;
			width: 5px;
			height: 5px;
			border-radius: 50%;
			margin-right: 5px;
			margin-top: 2px;
		}
	}
	&.st-2 {
		padding: var(--cl-status-type2-padding);
		height: var(--cl-status-type2-height);
		border-radius: var(--cl-status-type2-border-radius);
		font-size: var(--cl-status-type2-font-size);
		font-weight: var(--cl-status-type2-font-weight);
		line-height: 1;

		&::before {
			content: '';
			display: inline-flex;
			width: 6px;
			height: 6px;
			border-radius: 2px;
			margin-right: 8px;
			margin-top: 2px;
		}
	}
}
// ------------ </Statuses> -----------

// ------------ <Tags> ------------
.cl-tag {
	display: inline-flex;
	align-items: center;
	justify-content: space-between;
	padding: var(--cl-tag-type1-padding);
	height: var(--cl-tag-type1-height);
	border-radius: var(--cl-tag-type1-border-radius);
	font-size: var(--cl-tag-type1-font-size);
	font-weight: var(--cl-tag-type1-font-weight);
	background: #F5F5F5;
	border: 1px solid rgba(191, 198, 218, 0.5);
	line-height: 1;
	color: var(--cl-color-dark-1);

	&.c-info {
		@include cl-tag('info');

		a.del-tag { background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 16 16' fill=%22none%22 xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.3335 3.33325L12.6668 12.6666' stroke='%23219FFF' stroke-width='1.5' stroke-linecap=%22round%22 stroke-linejoin=%22round%22/%3E%3Cpath d='M3.33318 12.6666L12.6665 3.33325' stroke='%23219FFF' stroke-width='1.5' stroke-linecap=%22round%22 stroke-linejoin=%22round%22/%3E%3C/svg%3E"); }
	}
	&.c-success {
		@include cl-tag('success');

		a.del-tag { background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 16 16' fill=%22none%22 xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.3335 3.33325L12.6668 12.6666' stroke='%2317BD8D' stroke-width='1.5' stroke-linecap=%22round%22 stroke-linejoin=%22round%22/%3E%3Cpath d='M3.33318 12.6666L12.6665 3.33325' stroke='%2317BD8D' stroke-width='1.5' stroke-linecap=%22round%22 stroke-linejoin=%22round%22/%3E%3C/svg%3E"); }
	}
	&.c-warning {
		@include cl-tag('warning');

		a.del-tag { background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 16 16' fill=%22none%22 xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.3335 3.33325L12.6668 12.6666' stroke='%23FFA114' stroke-width='1.5' stroke-linecap=%22round%22 stroke-linejoin=%22round%22/%3E%3Cpath d='M3.33318 12.6666L12.6665 3.33325' stroke='%23FFA114' stroke-width='1.5' stroke-linecap=%22round%22 stroke-linejoin=%22round%22/%3E%3C/svg%3E"); }
	}
	&.c-danger {
		@include cl-tag('danger');

		a.del-tag { background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 16 16' fill=%22none%22 xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.3335 3.33325L12.6668 12.6666' stroke='%23FF4E3E' stroke-width='1.5' stroke-linecap=%22round%22 stroke-linejoin=%22round%22/%3E%3Cpath d='M3.33318 12.6666L12.6665 3.33325' stroke='%23FF4E3E' stroke-width='1.5' stroke-linecap=%22round%22 stroke-linejoin=%22round%22/%3E%3C/svg%3E"); }
	}

	a.del-tag {
		background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 16 16' fill=%22none%22 xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.3335 3.33325L12.6668 12.6666' stroke='%23424B68' stroke-width='1.5' stroke-linecap=%22round%22 stroke-linejoin=%22round%22/%3E%3Cpath d='M3.33318 12.6666L12.6665 3.33325' stroke='%23424B68' stroke-width='1.5' stroke-linecap=%22round%22 stroke-linejoin=%22round%22/%3E%3C/svg%3E");
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 11px auto;
		display: inline-flex;
		width: 10px;
		height: 10px;
		text-decoration: none;
		margin-left: 7px;
	}
}
// ------------ </Tags> -----------

// ------------ <Tables> -----------
.table-block {
	&.bordered {
		border: 1px solid var(--cl-color-dark-5);
		&.table-r8 {
			border-radius: 8px;
		}
		&.table-r15 {
			border-radius: 15px;
		}
		table {
			margin: 0;
		}
		tbody {
			tr:last-child > td {
				border-bottom: none;
			}
		}

		&.c-light {
			border-color: var(--bs-border-color);
		}
	}
	&.bg-w {
		background-color: #FFF;
	}
}
.table {
	&.table-overview {
		tr > td:not(:last-child) {
			border-right: 1px solid var(--bs-border-color);
		}
		tr > th:not(:last-child) {
			border-right: 1px solid var(--bs-border-color);
		}
		thead {
			td,th {
				text-align: center;
				vertical-align: middle;
				font-weight: 700;
				font-size: 12px;
				line-height: 16px;
				text-align: center;
				letter-spacing: 0.05em;
				text-transform: uppercase;
				color: var(--cl-color-dark-3);
			}
		}
		tbody {
			td,th {
				text-align: center;
				vertical-align: middle;
				font-weight: 500;
				font-size: 14px;
				line-height: 22px;
				color: var(--cl-color-dark-3);
			}
		}
	}
	& > :not(caption) > * > * {
		padding: .75rem .5rem;
	}
	thead {
		th {
			color: var(--cl-color-dark-4);
			font-size: 12px;
			font-weight: 700;
			line-height: 16px;
			letter-spacing: 0.05em;
			text-transform: uppercase;
			vertical-align: middle;

			.sort-btn {
				color: var(--cl-color-dark-4);
				line-height: 16px;
				text-decoration: none;

				svg {
					margin-top: -2px;
				}
			}
		}
	}
	tbody {
		font-size: 14px;

		&.vh-middle {
			th, td {
				vertical-align: middle;
			}
		}
	}
	.check-col {
		width: 40px;
	}
	.action-col {
		width: 92px;
	}
}
// ------------ </Tables> -----------

// ------------ <Dropdowns> ------------ 
.dropdown {
	display: inline-flex;

	.dropdown-toggle {
		&::after {
			display: none;
		}
		&.has-arrow {
			margin-right: 18px;

			&::after {
				display: block;
				background: url('../images/icons/arrow-down.svg') no-repeat center center;
				border: none;
				width: 14px;
				height: 14px;
				position: absolute;
				right: -18px;
			}
		}
		&.menu-arrow {
			position: relative;

			&.show::before {
				content: '';
				background: url(../images/icons/dropdown-arrow.svg) no-repeat center center;
				display: block;
				width: 22px;
				height: 16px;
				position: absolute;
				left: 0;
				right: 0;
				margin: 0 auto;
				bottom: -34px;
				z-index: 9999;
			}
		}
	}

	.dropdown-menu {
		border: none;
		border-radius: 12px;
		background: #FFFFFF;
		box-shadow: 0px 5px 30px rgba(22, 31, 36, 0.15);
		position: relative;
		padding: 12px 10px;
		margin-top: 28px !important;

		&.dropdown-base {
			width: 230px;
			margin-right: -20px !important;

			.nav-dropdown {
				margin: 0;
			}
		}

		.nav-dropdown {
			margin: 20px 0 0;
			padding: 0;

			.nav-item {
				display: flex;
				align-items: center;
				height: 52px;
				padding: 0 20px;
				text-decoration: none;
				border-radius: 4px;
				color: var(--cl-color-dark-3);
				@include hd(6);
				font-weight: 700;
				
				&:not(:last-child) {
					margin-bottom: 1px;
				}

				svg {
					width: 20px;
					height: auto;
					margin-right: 10px;
				}

				&:hover,
				&.active {
					background: var(--cl-color-primary-shade);
				}
			}

			hr {
				border: none;
				opacity: 1;
				height: 1px;
				background: #ECF0FB;
				margin: 1px 0;
			}
		}
	}
	&.dropdown-settings {
		.dropdown-menu {
			width: 320px;
		}
		.nav-dropdown {
			margin: 0;
			.nav-item {
				&:hover,
				&.active {
					background: var(--cl-color-primary);
					color: #FFF;
				}
			}
		}
	}
	&.dropdown-notifications {
		.notifications-count {
			display: none;

			&.has-items {
				display: block;
				@include flex-square(14px);
				background: var(--cl-color-status-danger);
				border-radius: 50%;
				color: #FFF;
				font-size: 9px;
				font-weight: 700;
				position: absolute;
				top: 0;
				left: 0;
				right: -10px;
				margin: 6px auto;
			}
		}
	}
	&.dropdown-profile {
		.dropdown-menu {
			width: 320px;
		}
		.user-info {
			border-radius: 10px;
			background: var(--cl-color-primary);
			color: #FFF;
			padding: 20px;

			img {
				max-width: 48px;
			}

			.user-email {
				color: var(--cl-color-text-light-1);
			}
		}
	}
}
// ------------ </Dropdowns> ------------ 

// ------------ <Form Elems> ------------ 
.form-control,
.form-select {
	@include placeholder(var(--cl-color-dark-4),14px);
	border-radius: 8px;
	border: 1px solid var(--cl-color-dark-5);
	color: var(--cl-color-dark-4);
	width: 100%;
	outline: none;
	background-color: transparent;
	box-shadow: none;
	height: 50px;
	padding: 0 25px;
	@include txt-input('lg');

	&:focus,
	&:active {
		outline: none;
		color: var(--cl-color-dark-4);
		border-color: var(--cl-color-dark-4);
		background: transparent;
		box-shadow: none;
	}

	&.search-inp {
		height: 42px;
		border-radius: 50px;
		border-color: var(--cl-color-dark-6);
		padding: 0 20px;
	}

	&.s-md {
		@include txt-input('md');
		height: 40px;
		padding: 0 20px;
	}
	&.s-sm {
		@include txt-input('sm');
		height: 32px;
		padding: 0 15px;
	}
	&.disabled {
		background-color: var(--cl-color-dark-6);
		pointer-events: none;
	}
}
textarea.form-control {
	padding: 15px 25px;
	min-height: 100px;
}
.form-select {
	color: var(--cl-color-dark-1);
	background-color: #FFF;

	&:active,
	&:focus {
		color: var(--cl-color-dark-1);
		background-color: #FFF;
	}

	option {
		box-shadow: none;
		background: #FFF;
		color: var(--cl-color-dark-1);
	}

	&.select-filter {
		height: 32px;
		border-radius: 8px;
		border-color: var(--cl-color-dark-5);
		background-color: transparent;
		padding: 0 20px;
		max-width: 180px;
		color: var(--cl-color-dark-4);

		&:active,
		&:focus {
			background-color: #FFF;
		}

		&.w-md {
			max-width: 100px;
		}
	}
}
.form-check {
	&.s-md {
		
	}
}
.form-check-label {
	@include ph(4);
	color: var(--cl-color-dark-3);
	font-weight: 400;
}
.form-check-input {
	--checkbox-size: 24px;
	--bs-border-color: var(--cl-color-dark-5);
	width: var(--checkbox-size);
	height: var(--checkbox-size);
	margin: 0;

	&:checked {
		background-color: var(--cl-color-primary);
		border-color: var(--cl-color-primary);
	}

	&:checked[type="radio"] {
		--bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%2300AEEF'/%3e%3c/svg%3e");
		background-color: #FFF;
	}

	&.s-md {
		--checkbox-size: 18px;
	}
	&.s-sm {
		--checkbox-size: 14px;
	}
}
.form-switch {
	.form-check-input {
		cursor: pointer;
		--checkbox-size: 20px;
		--bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23FFF'/%3e%3c/svg%3e");
		background-color: var(--cl-color-dark-5);
		width: 40px;
		height: 24px;

		&:focus,
		&:active {
			--bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23FFF'/%3e%3c/svg%3e");
		}

		&:checked {
			--bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23FFF'/%3e%3c/svg%3e");
			background-color: var(--cl-color-primary);
		}
		&.s-sm {
			--checkbox-size: 14px;
			width: 36px;
			height: 20px;
			margin-top: 2px;
		}
	}
}
.inp-bl {
	position: relative;

	.form-control {
		position: relative;
		z-index: 2;
	}

	&.has-icn-s {
		.form-control {
			padding-left: 54px;
		}
	}
	&.has-icn-e {
		.form-control {
			padding-right: 54px;
		}
	}
	.icn {
		width: 17px;
		height: 17px;
		position: absolute;
		z-index: 2;
		top: 50%;
		margin-top: -8px;
		color: var(--cl-color-dark-4);
		line-height: 1;

		&.icn-pos-s {
			left: 25px;
		}
		&.icn-pos-e {
			right: 25px;
		}

		svg {
			width: 100%;
			height: 100%;
		}
	}

	.toggle-pass-view {
		z-index: 3;
		cursor: pointer;
	}

	&.s-sm {
		.icn {
			width: 16px;
			height: 16px;
		}
	}
}
.form-number {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	border-radius: 5px;
	overflow: hidden;
	background: var(--cl-color-dark-7);

	&.c-light {
		background: #FFF;
		border: 1px solid #E5E8F0;
	}

	.num-contrls {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		width: 25px;
		height: 25px;
		line-height: 1;
		font-size: 20px;
		color: var(--cl-color-dark-3);
		text-decoration: none;
		border: none;
		background: transparent;

		&.ctr-minus {
			padding-bottom: 4px;
			font-size: 24px;
		}
	}

	input[type=number]::-webkit-inner-spin-button,
	input[type=number]::-webkit-outer-spin-button {
	  -webkit-appearance: none;
	  margin: 0;
	}
	input[type=number] {
	  -moz-appearance: textfield;
	  border: none;
	  background: transparent;
	  border-left: 1px solid #E5E8F0;
	  border-right: 1px solid #E5E8F0;
	  display: inline-flex;
	  align-items: center;
	  justify-content: center;
	  text-align: center;
	  width: 30px;
	  height: 25px;
	  @include hd(6);
	  font-weight: 700;
	  color: var(--cl-color-dark-3);
	}
}
// ------------ </Form Elems> ------------ 

// ----------------- <Main Template Style> -----------------
.template {
	position: relative;
	background: var(--cl-color-dark-7);

	// ------------ <Template Main Panels> ------------ 
	--sidebar-width: 240px;
	--topbar-height: 75px;

	.sidebar {
		position: fixed;
		z-index: 2;
		left: 0;
		top: 0;
		bottom: 0;
		width: var(--sidebar-width);
		height: 100vh;
		background: var(--cl-color-bg-1);
		color: #FFF;
	}
	.topbar {
		position: fixed;
		z-index: 2;
		left: var(--sidebar-width);
		top: 0;
		right: 0;
		height: var(--topbar-height);
		background: #FFF;
		box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.02);
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding: 0 48px;
	}
	main {
		position: relative;
		z-index: 1;
		overflow: hidden;
		padding: var(--topbar-height) 0 0 var(--sidebar-width);
		min-height: 100vh;
	}
	// ------------ </Template Main Panels> ------------ 

	// ------------ <Template Panels Elems> ------------ 
	.sidebar {
		.sb-header {
			display: flex;
			align-items: center;
			height: var(--topbar-height);
			border-bottom: 1px solid rgba(255,255,255,.1);
			padding: 0 32px;
		}
		.sb-main {
			padding: 20px 12px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			height: calc( 100vh - var(--topbar-height) );
			overflow: auto;

			/* width */
			scrollbar-width: thin;
			&::-webkit-scrollbar {
				width: 6px;
				background: var(--cl-color-dark-5);
			}

			/* Track */
			scrollbar-color: var(--cl-color-dark-4) var(--cl-color-dark-5);
			::-webkit-scrollbar-track {
				background: var(--cl-color-dark-4);
				border-radius: 0px;
			}

			/* Handle */
			&::-webkit-scrollbar-thumb {
				background: var(--cl-color-dark-4); 
				border-radius: 0;
			}

			/* Handle on hover */
			&::-webkit-scrollbar-thumb:hover {
				background: var(--cl-color-dark-3); 
			}
		}
		.main-nav {
			margin: 0;
			padding: 0;

			.nav-item {
				display: flex;
				align-items: center;
				height: 48px;
				padding: 0 20px;
				text-decoration: none;
				border-radius: 8px;
				border-left: 3px solid transparent;
				color: var(--cl-color-link-1);
				@include ph(4);
				font-weight: 700;
				
				&:not(:last-child) {
					margin-bottom: 12px;
				}

				svg, img {
					color: var(--cl-color-link-1);
					margin-right: 12px;
					-webkit-filter: invert(.75); /* safari 6.0 - 9.0 */
					filter: invert(.75);
				}

				&:hover,
				&.active {
					svg, img {
						margin-right: 12px;
						-webkit-filter: invert(1); /* safari 6.0 - 9.0 */
						filter: invert(1);
					}
					color: #FFF;
					background: linear-gradient(90deg, #3945A4 0%, rgba(57, 69, 164, 0) 100%);
					border-left: 3px solid var(--cl-color-primary);
				}
			}
		}
		.logout {
			color: var(--cl-color-primary);
			text-decoration: none;
			@include ph(4);
			font-weight: 700;
			padding: 0 20px;
			display: flex;
			align-items: center;
			height: 48px;

			svg {
				margin-right: 12px;
			}			
		}
	}
	.search-block {
		width: 388px;

		.inp-bl {
			.icn {
				margin-top: -10px;

				&.icn-pos-s {
					left: 20px;
				}
			}
			&.has-icn-s {
				.form-control {
					padding-left: 47px;
				}
			}
		}

		&.search-filter {
			width: 230px;

			.form-control.search-inp {
				height: 32px;
				border-radius: 8px;
				border-color: var(--cl-color-dark-5);
				padding-right: 20px;
			}
		}

		&.pos-end {
			margin-left: auto;
			width: 269px;
		}
	}
	.sec-main {
		padding: 25px 50px;
	}
	// ------------ </Template Panels Elems> ------------ 

	// ------------ <Template Body Elems> ------------ 
	.cl-card {
		background: #FFF;
		border-radius: 16px;
		padding: 25px 40px;

		&.s-md {
			padding: 20px 25px;
		}
		&.card-full {
			width: 100%;
		}
		&.card-table {
			width: 1100px;
		}
		&.card-main {
			width: 820px;
		}
		&.card-sidebar {
			width: 260px;
		}
	}
	.cl-plate {
		background: #FFF;
		border-radius: 12px;
		padding: 20px;
		width: 350px;

		&.task-head {
			padding: 10px 15px;
			display: inline-flex;
			align-items: center;
			justify-content: flex-start;
			border-top: 2px solid #1560BD;

			.lab {
				font-size: 14px;
				font-weight: 700;
				color: var(--cl-color-dark-2);
				text-transform: uppercase;
				margin-right: 10px;
			}
			.num {
				border: 1px solid var(--cl-color-dark-6);
				border-radius: 30px;
				width: 34px;
				height: 22px;
				font-size: 14px;
				font-weight: 700;
				color: var(--cl-color-dark-2);
				display: flex;
				align-items: center;
				justify-content: center;
				text-align: center;
			}

			&.c-info {
				border-color: var(--cl-color-status-info);
			}
			&.c-success {
				border-color: var(--cl-color-status-success);
			}
			&.c-warning {
				border-color: var(--cl-color-status-warning);
			}
			&.c-danger {
				border-color: var(--cl-color-status-danger);
			}
		}
	}
	.cl-tabs-line {
		display: inline-flex;
		align-items: center;
		height: var(--topbar-height);

		.cl-tab {
			display: inline-flex;
			align-items: center;
			justify-content: space-between;
			@include hd(6);
			font-weight: 700;
			color: var(--cl-color-dark-1);
			padding: 5px 27px 5px 27px;
			background: var(--cl-color-dark-7);
			border-radius: 12px;
			height: 57px;
			position: relative;
			min-width: 270px;

			a {
				line-height: 1;
				margin-top: -2px;
			}

			img {
				max-width: 14px;
			}

			/*&:after {
				content: '';
				background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill=%22none%22 xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.3335 3.33325L12.6668 12.6666' stroke='%23424B68' stroke-width='1.5' stroke-linecap=%22round%22 stroke-linejoin=%22round%22/%3E%3Cpath d='M3.33318 12.6666L12.6665 3.33325' stroke='%23424B68' stroke-width='1.5' stroke-linecap=%22round%22 stroke-linejoin=%22round%22/%3E%3C/svg%3E");
				background-size: 14px auto;
				display: block;
				width: 16px;
				height: 16px;
				position: absolute;
				z-index: 2;
				right: 27px;
				top: 20px;
				cursor: pointer;
			}*/
		}
	}
	.members-list {
		display: inline-flex;
		align-items: center;

		img, .more-members, .member-without-picture {
			background: #D6F4FF;
			border: 1px solid #92d8f5;
			@include flex-square(35px);
			border-radius: 50%;
			overflow: hidden;
			font-size: 12px;
			font-weight: 500;
			line-height: 1;
			color: var(--cl-color-dark-3);

			&:not(:first-child) {
				margin-left: -15px;
			}
			//border-color: lightseagreen;
		}
		.more-members{
			font-weight: bold;
			color: red;
		}
		.member-without-picture{
			background: #D6F4FF;
		}
	}
	.cl-pagination {
		select {
			background: #FFF;
			@include hd(6);
			font-weight: 500;
			color: var(--cl-color-dark-1);
			display: inline-flex;
			align-items: center;
			justify-content: center;
			height: 37px;
			padding: 0 10px;
			border: 1px solid var(--cl-color-dark-5);
			border-radius: 8px;
			max-width: 58px;
			text-align: center;
		}
		.pages {
			a {
				@include flex-button(37px);
				width: 37px;
				min-width: 37px;
				text-decoration: none;
				@include hd(6);
				font-weight: 500;
				color: var(--cl-color-dark-3);
				border-radius: 8px;
				padding: 0;
				border: 1px solid var(--cl-color-dark-5);
				background: transparent;
			
				&:hover {
					color: var(--cl-color-dark-3);
					background: var(--cl-color-primary-shade);
				}

				&.active {
					background: var(--cl-color-primary);
					color: #FFF;
				}

				svg {
					width: 14px;
					height: 14px;
				}

				&:not(:last-child) {
					margin-right: 9px;
				}
			}
		}
	}
	.cl-collapse {
		background: #FFF;
		padding: 20px 25px;
		border-radius: 15px;
		border: 1px solid var(--cl-color-dark-6);

		&.s-md {
			padding: 20px 25px;
		}
		&.w-full {
			width: 100%;
		}
		&.w-table {
			width: 1100px;
		}
		&.w-main {
			width: 820px;
		}
		&.w-sidebar {
			width: 260px;
		}

		&.c-transp {
			border: none;
			background: transparent;
			padding: 10px 0;

			> .cl-collp-head {
				padding-bottom: 15px;
				border-bottom: 1px solid var(--cl-color-dark-5);
			}
		}

		.cl-collp-head {
			cursor: pointer;
			align-items: center;

			.cl-collp-arrow {
				width: 22px;
				transition: 0.2s all;
				transform: rotate(180deg);
				opacity: .5;
			}

			&:hover {
				.cl-collp-arrow {
					opacity: .8;
				}
			}

			&[aria-expanded=true] {
				.cl-collp-arrow {
					transform: rotate(0deg);
					opacity: 1;
				}
			}
		}
		.cl-collp-body {
			margin-top: 20px;

			&.hr-top {
				padding-top: 20px;
				border-top: 1px solid var(--cl-color-dark-5);
			}
		}
	}
	.mini-ava {
		@include flex-square(32px);
		border-radius: 50%;
		overflow: hidden;
		background: var(--cl-color-primary-shade);
	}
	.nav-pane {
		&.s-sm {
			width: 100%;
			max-width: 228px;
		}
		.cl-btn {
			&.c-shaded {
				justify-content: space-between;
				color: var(--cl-color-dark-1);
				background: #FFF;
				@include txt-input('md');

				&::after {
					filter: grayscale(1);
				}

				&:hover,
				&:focus,
				&:active,
				&.active {
					background: rgba(0, 174, 239, 0.1);

					&::after {
						filter: grayscale(0);
					}
				}
			}
		}
	}
	.cl-tabs {
		.cl-tabs-line {
			display: flex;
			align-items: flex-end;
			border-bottom: 1px solid var(--cl-color-dark-6);

			a, button {
				display: inline-flex;
				align-items: center;
				justify-content: center;
				text-align: center;
				text-decoration: none;
				background: none;
				border: none;
				height: 42px;
				padding: 0 0 20px;
				@include ph(4);
				color: var(--cl-color-dark-3);
				font-weight: 700;
				border-bottom: 2px solid transparent;
				margin-bottom: -1px;
				
				&:not(:last-child) {
					margin-right: 40px;
				}

				&:hover,
				&:focus,
				&:active {
					color: var(--cl-color-dark-1);
					border-color: var(--cl-color-dark-1);
				}

				&.active {
					color: var(--cl-color-primary);
					border-color: var(--cl-color-primary);
				}
			}
		}
	}

	.drag-upload-files {
		background: rgba(0, 174, 239, 0.06);
		border: 1px dashed rgba(0, 174, 239, 0.5);
		border-radius: 8px;
		text-align: center;
		padding: 30px 20px;
		color: var(--cl-color-dark-1);
		cursor: pointer;
	}

	.comments-bl {
		.comments-item {
			&:not(:last-child) {
				margin-bottom: 25px;
			}
			.avatar {
				width: 44px;
				border-radius: 50%;
				overflow: hidden;
			}
			.name {
				font-size: 12px;
				font-weight: 700;
				color: var(--cl-color-dark-2);
				margin: 0 0 10px;
			}
			.comment {
				background: #F5F5F5;
				border-radius: 10px 10px 10px 0px;
				padding: 10px 20px;
				font-weight: 500;
				font-size: 14px;
				line-height: 22px;
				color: #555F7E;
				margin: 0 0 10px;
			}
			.date {
				font-weight: 500;
				font-size: 10px;
				line-height: 14px;
			}
		}

		.type-message {
			margin-top: 50px;
			background: #F4F7FF;
			border: 1px solid #ECF0FB;
			border-radius: 100px;
			height: 60px;
			padding: 5px 5px 5px 28px;

			.line {
				height: 20px;
				width: 1px;
				background: rgba(16, 40, 68, 0.3);;
			}

			textarea {
				height: 26px;
				line-height: 26px;
				border: none;
				padding: 0 10px;
				background: transparent;
				border: none;
				resize: none;
				width: 100%;

				&:active,
				&:focus {
					outline: none;
					border: none;
				}
			}

			.cl-btn {
				margin-left: 15px;
				border-radius: 200px;
			}
		}
	}

	.progress-bar {
		height: 3px;
		width: 100%;
		background: var(--cl-color-dark-6);
		border-radius: 10px;
		overflow: hidden;
		position: relative;

		.prog-line {
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			background: var(--cl-color-dark-2);
			border-radius: 10px;

			&.c-info {
				background: var(--cl-color-status-info);
			}
			&.c-success {
				background: var(--cl-color-status-success);
			}
			&.c-warning {
				background: var(--cl-color-status-warning);
			}
			&.c-danger {
				background: var(--cl-color-status-danger);
			}
		}
	}
	.scrolleble-bl {
		width: 100%;
		max-width: 100%;
		overflow-y: hidden;
		overflow-x: auto;

		/* width */
		scrollbar-width: thin;
		&::-webkit-scrollbar {
			width: 6px;
			background: var(--cl-color-dark-5);
		}

		/* Track */
		scrollbar-color: var(--cl-color-dark-4) var(--cl-color-dark-5);
		::-webkit-scrollbar-track {
			background: var(--cl-color-dark-4);
			border-radius: 0px;
		}

		/* Handle */
		&::-webkit-scrollbar-thumb {
			background: var(--cl-color-dark-4); 
			border-radius: 0;
		}

		/* Handle on hover */
		&::-webkit-scrollbar-thumb:hover {
			background: var(--cl-color-dark-3); 
		}
	}
	// ------------ </Template Body Elems> ------------ 
}
// ----------------- </Main Template Style> -----------------
.modal {
	--bs-modal-border-radius: 16px;
	--bs-modal-padding: 48px 48px;
	--bs-modal-head-padding: 30px 48px;

	.modal-dialog {
		max-width: 640px;

		&.s-xl {
			max-width: 800px;
		}
		&.s-sm {
			max-width: 550px;
		}
	}
	.modal-content {
		position: relative;
		border: none;
	}
	.cl-btn-close {
		position: absolute;
		z-index: 3;
		right: -24px;
		top: -24px;
	}
	.modal-head {
		padding: var(--bs-modal-head-padding);
		border-bottom: 1px solid var(--cl-color-dark-6);
	}
	.modal-body {

	}
}
