/* ******************* <Variables> ********************** */
:root {
	// Colors
	--bs-body-color: #1C284B;

	--cl-color-primary: #00AEEF;
	--cl-color-primary-dark: #1883B1;
	--cl-color-primary-shade: #D6F4FF;

	--cl-color-dark-1: #1C284B;
	--cl-color-dark-2: #424B68;
	--cl-color-dark-3: #5C6682;
	--cl-color-dark-4: #5C6682;
	--cl-color-dark-5: #BFC6DA;
	--cl-color-dark-6: #ECF0FB;
	--cl-color-dark-7: #F4F7FF;

	--cl-color-status-info: #219FFF;
	--cl-color-status-info-shade: #E9F6FF;
	--cl-color-status-success: #17BD8D;
	--cl-color-status-success-shade: #E9FBF6;
	--cl-color-status-warning: #FFA114;
	--cl-color-status-warning-shade: #FFF7EB;
	--cl-color-status-danger: #FF4E3E;
	--cl-color-status-danger-shade: #FFEDEC;

	--cl-color-bg-1: #2D388A;

	--cl-color-text-light-1: #C6DAF4;
	--cl-color-link-1: #9AA3EA;

	--cl-color-gray-1: #333333;
	--cl-color-gray-2: #4F4F4F;
	--cl-color-gray-3: #828282;
	--cl-color-gray-4: #BDBDBD;
	--cl-color-gray-5: #E0E0E0;
	--cl-color-gray-6: #F2F2F2;
	--cl-color-red: #EB5757;
	--cl-color-orange: #F2994A;
	--cl-color-yellow: #F2C94C;
	--cl-color-green-1: #219653;
	--cl-color-green-2: #27AE60;
	--cl-color-green-3: #6FCF97;
	--cl-color-blue-1: #2F80ED;
	--cl-color-blue-2: #2D9CDB;
	--cl-color-blue-3: #56CCF2;
	--cl-color-purple-1: #9B51E0;
	--cl-color-purple-2: #BB6BD9;
	
	// Typography
	--font-family-base: 'Satoshi-Variable', var(--bs-font-sans-serif);
	--font-size-root: 16px;
	--font-size-base: 1rem;
	--font-line-height: 1.3;

	--headings-font-family: 'Roobert', var(--bs-font-sans-serif);
	--headings-font-weight: 400;
	--headings-line-height: 1.15;

	// Heading Style
	--heading-h1-font-size: 36px;
	--heading-h1-line-height: 46px;
	--heading-h2-font-size: 30px;
	--heading-h2-line-height: 40px;
	--heading-h3-font-size: 24px;
	--heading-h3-line-height: 34px;
	--heading-h4-font-size: 18px;
	--heading-h4-line-height: 26px;
	--heading-h5-font-size: 16px;
	--heading-h5-line-height: 24px;
	--heading-h6-font-size: 14px;
	--heading-h6-line-height: 22px;

	// Paragraph
	--paragraph-p1-font-size: 20px;
	--paragraph-p1-line-height: 30px;
	--paragraph-p2-font-size: 18px;
	--paragraph-p2-line-height: 28px;
	--paragraph-p3-font-size: 16px;
	--paragraph-p3-line-height: 26px;
	--paragraph-p4-font-size: 14px;
	--paragraph-p4-line-height: 22px;
	--paragraph-p5-font-size: 12px;
	--paragraph-p5-line-height: 20px;
	
	// Text ----------
	// --- Table Head
	--table-head-1-font-size: 11px;
	--table-head-1-font-weight: 500;
	--table-head-2-font-size: 10px;
	--table-head-2-font-weight: 500;

	// --- Btn Text
	--btn-lg-font-size: 18px;
	--btn-lg-font-weight: 600;
	--btn-md-font-size: 14px;
	--btn-md-font-weight: 600;
	--btn-sm-font-size: 13px;
	--btn-sm-font-weight: 600;

	// --- Tag Text
	--tag-md-font-size: 12px;
	--tag-md-font-weight: 600;
	--tag-sm-font-size: 10px;
	--tag-sm-font-weight: 600;
	
	// --- Input Text
	--input-lg-font-size: 14px;
	--input-lg-font-weight: 500;
	--input-md-font-size: 14px;
	--input-md-font-weight: 500;
	--input-sm-font-size: 13px;
	--input-sm-font-weight: 500;

	// --- Statuses
	--cl-status-type1-height: 28px;
	--cl-status-type1-padding: 0 10px;
	--cl-status-type1-border-radius: 8px;
	--cl-status-type1-font-size: 14px;
	--cl-status-type1-font-weight: 500;
	--cl-status-type2-height: 26px;
	--cl-status-type2-padding: 0 16px;
	--cl-status-type2-border-radius: 14px;
	--cl-status-type2-font-size: 14px;
	--cl-status-type2-font-weight: 700;

	// --- Tags
	--cl-tag-type1-height: 26px;
	--cl-tag-type1-padding: 0 10px;
	--cl-tag-type1-border-radius: 6px;
	--cl-tag-type1-font-size: 12px;
	--cl-tag-type1-font-weight: 500;
}

// Grid Breakpoints
$grid-xs: 0;
$grid-sm: 576px;
$grid-md: 768px;
$grid-lg: 992px;
$grid-xl: 1200px;
$grid-xxl: 1400px;

// Screen sizes
// -------------------------
$screen-size-wide: $grid-xxl - 1;
$screen-size-desktop: $grid-xl - 1;
$screen-size-tablet: $grid-lg - 1;
$screen-size-mobile: $grid-md - 1;
/* ******************* </Variables> ********************** */
.txt-color-primary {
	color: var(--cl-color-primary);
}
.txt-color-info {
	color: var(--cl-color-status-info);
}
.txt-color-success {
	color: var(--cl-color-status-success);
}
.txt-color-warning {
	color: var(--cl-color-status-warning);
}
.txt-color-danger {
	color: var(--cl-color-status-danger);
}

@for $i from 1 through 7 {
	.txt-color-dark-#{$i} {
        color: var(--cl-color-dark-#{$i});
	}
}
@for $i from 1 through 7 {
	.bg-color-dark-#{$i} {
        background-color: var(--cl-color-dark-#{$i});
	}
}


/* ******************* <Sizes> *********************** */
@for $i from 0 through 100 {
	.fs-#{$i} {
		font-size: #{$i}px;
	}
}

@media (min-width: $grid-sm) {
	@for $i from 0 through 100 {
		.fs-sm-#{$i} {
			font-size: #{$i}px;
		}
	}
}

@media (min-width: $grid-md) {
	@for $i from 0 through 100 {
		.fs-md-#{$i} {
			font-size: #{$i}px;
		}
	}
}

@media (min-width: $grid-lg) {
	@for $i from 0 through 100 {
		.fs-lg-#{$i} {
			font-size: #{$i}px;
		}
	}
}

@media (min-width: $grid-xl) {
	@for $i from 0 through 100 {
		.fs-xl-#{$i} {
			font-size: #{$i}px;
		}
	}
}

@media (min-width: $grid-xxl) {
	@for $i from 0 through 100 {
		.fs-xxl-#{$i} {
			font-size: #{$i}px;
		}
	}
}

/* ******************* </Sizes> ********************** */

.fw-thin {
	font-weight: 100
}

.fw-elight {
	font-weight: 200
}

.fw-light {
	font-weight: 300
}

.fw-normal {
	font-weight: 400
}

.fw-medium {
	font-weight: 500
}

.fw-semi {
	font-weight: 600
}

.fw-bold {
	font-weight: 700
}

.fw-heavy {
	font-weight: 800
}

.fw-black {
	font-weight: 900
}

.tt-upper {
	text-transform: uppercase
}

.tt-none {
	text-transform: none
}

.tt-lower {
	text-transform: lowercase
}

.td-under {
	text-decoration: underline
}

.strike {
	text-decoration: line-through
}