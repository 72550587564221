@import "src/template/scss/utilites";

/* ------------ <Page Login> ------------ */
.page-login {
    background: #1C284B;

    .sec-login {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 100vh;
        padding: 30px 0;

        .logo {
            @media (min-width: 1260px) {
                max-width: 600px;
            }
        }

        .form-block {
            width: 100%;
            max-width: 556px;
            background: #FFF;
            border-radius: 16px;
            margin: 0 0 0 auto;
            padding: 50px 45px;
        }

        @media(max-width: $screen-size-tablet) {
            .form-block {
                padding: 40px 35px;
            }
        }
        @media(max-width: $screen-size-mobile) {
            padding: 40px 0;
            text-align: center;

            img {
                max-width: 200px;
            }

            .form-block {
                margin: 40px 0 0;
                padding: 35px 25px;
            }
        }
    }
}
/* ------------ </Page Login> ------------ */
.react-select{
    .react-select__control{
       height: 50px;
        border-radius: 8px;
        padding-left: 15px;
    }
}