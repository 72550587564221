@import "src/template/scss/mixin";
.sec-profile {
  .cl-card {
    width: 470px;
  }
  .image-avatar{
    @include flex-square(70px);
    background: var(--cl-color-dark-7);
    border: 1px solid var(--cl-color-dark-5);
    border-radius: 50%;
    overflow: hidden;
  }
}
.react-select{
  .react-select__control{
    height: 50px;
    border-radius: 8px;
    padding-left: 15px;
  }
}