
.preloader {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
  max-height: 0;
  transition: 0.2s all;
  overflow: hidden;
  background: rgba(0,0,0,.7);
}
.preloader.active {
  max-height: 2000px;
  opacity: 1;
  z-index: 11999;
}
.preloader img {
  max-width: 150px;
}