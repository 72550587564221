@mixin flex-section-center($height){
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: $height;
}

@mixin flex-button($height){
	display: inline-flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	min-height: $height;
	text-decoration: none;
}

@mixin flex-square($size){
	display: inline-flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	width: $size;
	height: $size;
}
@mixin placeholder($color, $size: 16px) {
    &::-webkit-input-placeholder {
        color: $color;
		font-size: $size;
    }

    &::-moz-placeholder {
        color: $color;
		font-size: $size;
    }

    &:-moz-placeholder {
        color: $color;
		font-size: $size;
    }

    &:-ms-input-placeholder {
        color: $color;
		font-size: $size;
    }
}
// Heading Style
@mixin hd($i){
	font-size: var(--heading-h#{$i}-font-size);
	line-height: var(--heading-h#{$i}-line-height);
}
// Paragraph
@mixin ph($i){
	font-size: var(--paragraph-p#{$i}-font-size);
	line-height: var(--paragraph-p#{$i}-line-height);
}
// Text
@mixin txt-table-h($i){
	font-size: var(--table-head-#{$i}-font-size);
	font-weight: var(--table-head-#{$i}-font-weight);
	text-transform: uppercase;
	letter-spacing: 0.05em;
}
// Buttons
@mixin txt-btn($s){
    font-size: var(--btn-#{$s}-font-size);
    font-weight: var(--btn-#{$s}-font-weight);
}
// Tags
@mixin txt-tag($s){
    font-size: var(--tag-#{$s}-font-size);
    font-weight: var(--tag-#{$s}-font-weight);
}
// Inputs
@mixin txt-input($s){
    font-size: var(--input-#{$s}-font-size);
    font-weight: var(--input-#{$s}-font-weight);
}

// Status
@mixin cl-status($color){
	color: var(--cl-color-status-#{$color});
	background: var(--cl-color-status-#{$color}-shade);
	&::before { background: var(--cl-color-status-#{$color}); }
}

// Tag
@mixin cl-tag($color){
	color: var(--cl-color-status-#{$color});
	border-color: var(--cl-color-status-#{$color});
	background: var(--cl-color-status-#{$color}-shade);
}

// Panel
@mixin cl-panel($color){
	background: var(--cl-color-status-#{$color}-shade);
	border-color: var(--cl-color-status-#{$color});
}