// Heading Style
@for $i from 1 through 6 {
	h#{$i}, .h#{$i} {
        @include hd($i);
	}
}

// Paragraph
@for $i from 1 through 5 {
	.ph-#{$i} {
        @include ph($i);
	}
}

// Text
@for $i from 1 through 2 {
	.txt-table-h#{$i} {
        @include txt-table-h($i);
	}
}

.txt-btn-lg {
    @include txt-btn('lg');
}
.txt-btn-md {
    @include txt-btn('md');
}
.txt-btn-sm {
    @include txt-btn('sm');
}
.txt-tag-md {
    @include txt-tag('md');
}
.txt-tag-sm {
    @include txt-tag('sm');
}
.txt-input-lg {
    @include txt-input('lg');
}
.txt-input-md {
    @include txt-input('md');
}
.txt-input-sm {
    @include txt-input('sm');
}